































































.palydialog{
  width: 744px!important;
  .el-dialog__body{
    height: 420px;
    box-sizing: border-box!important;
    overflow: hidden!important;
    margin-bottom: 16px;
  }
  .play_content{
     width:675px;
     height: 400px;
    .video-component{
      width: 100%;
      height: 100%;
      background: #000;
      padding: 4px;
      border-radius: 4px;
      position: relative;
      overflow: hidden;
      video{
        width: 100%;
        height: 100%;
      }
    }
  }

}
