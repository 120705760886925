






































































































































































































































































































































































































































































































































































































































.re{
  .footer{
    .z-btn{
      margin: 0px 12px!important;
    }
  }
  .el-input__suffix{
    right: 12px!important;
  }
  .el-dialog__body{
    padding: 37px 40px 50px!important;
  }
  .el-upload-dragger:hover{
    border-color: #19B0B0!important;
  }
  .el-upload-dragger{
    border: 1px dashed  #19B0B0!important;
  }
}
.release{
  .upload-demo{
    .el-upload-dragger{
      width: 528px;
      height: 208px;
    }
  }
  .upload-two{
    .el-upload-dragger{
      width: 142px;
      height: 80px;
    }
  }
  .hiddenborder{
    .el-upload-dragger{
     border: none!important;
    }
  }
  .upicon{
    font-size: 26px;
    top: 2px;
    position: relative;
    margin-right: 6px;
    color: #19B0B0;
  }
  .up_class{
    padding:55px 0px 0px;
    position: relative;
    .up_title{
      font-size: 16px;
      font-weight: bold;
      color: #19B0B0;
      line-height: 17px;
    }
    .up_con{
      margin-top: 12px;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      text-align: left;
      padding-left: 147px;
    }
  }
  .uimg-conent{
    position: relative;
    padding: 22px 0;
    box-sizing: border-box;
    .l-h-20{
      line-height: 20px;
    }
    .i-font{
      font-size: 12px;
      font-weight: 400;
      color: #19B0B0;
      line-height: 11px;
      margin-top: 5px;
    }
  }
  .footer{
    text-align: center;
    margin-top: 42px;
  }
  .up_img{
    width: 142px;
    height: 80px;
    img{
      width: 100%;
      height: 100%;
    }
    .baclk_back{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, .6);
    }
    .icon_pos{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #FFFFFF;
      .white{
        color: #FFFFFF;
        font-size: 22px;
        margin-bottom: 7px;
      }
    }
  }
}
.vo-class{
  .el-input__inner{
    width: 426px;
    height: 28px;
    padding: 0 12px;
  }
  .el-form-item__label{
    padding: 0 22px 0 0!important;
    line-height: 27px!important;
  }
  .el-form-item__content{
    line-height: 26px!important;
  }
  .el-form-item{
    margin-bottom: 19px!important;
  }
  .lin-15{
    .el-form-item__content{
      line-height: 14px !important;
    }
    .el-form-item__label{
      padding: 0 22px 0 0!important;
    }
  }
  .progress{
    position: absolute;
    left: 157px;
    width: 265px;
    height: 80px;
    top: 0px;
    .viodname{
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      line-height: 18px;
      margin-top: 16px;
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      margin-bottom: 13px;
    }
    .el-progress-bar__inner{
      background: linear-gradient(-22deg, #73E2E2 0%, #28CCCC 100%);
    }
    .el-progress-bar__outer{
      background-color: #F1F1F1;
    }
    .el-progress-bar__innerText{
      color:black!important;
    }
  }
  .cover_progess{
    position: absolute;
    top: 0;
    left: 8%;
    .el-progress-circle{
      width: 80px!important;
      height: 80px!important;
    }
  }
}
.m-b-7{
  margin-bottom: 7px;
}
.redreq{
  color: #F56C6C;
  margin-right: 4px;
}
