





















































































































































































































































































































































































































































































































































































































































































































































































.v-content{
  .el-table .el-table__cell .cell{
    line-height: 1.4em !important;
  }
  .el-table .el-table__cell{
    padding: 0px 5px !important;
  }
  .dash{
    .z-btn {
      margin: 0 5px 0 6px;
      position: relative;
      &:after {
        position: absolute;
        content: '';
        width:1px;
        height: 14px;
        top: 50%;
        left: 0;
        margin:-7px 0 0 -6px;
        background: #EAEAEA;
      }
      &:first-child {
        &:after {
          display: none;
        }
      }
    }
  }
}

